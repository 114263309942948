/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, SeparateLineWrap, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Main"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-2x5rq6 --style4 js-anim  --anim2 --left --full" name={"uvod"} fullscreen={true} parallax={false} anim={"2"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38872/d82f9fc4fd134e9a990710378122e868_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--128" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Smart Medical Pad</span>"}>
              </Title>

              <Text className="text-box fs--43 w--300" style={{"maxWidth":800}} content={"<span style=\"color: rgb(198, 198, 198);\">Digital Medical Assistant</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim7 pb--60 pt--60" name={"nh5ix8gl49s"} anim={"7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box fs--62" content={"Better healthcare experience for all"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":650}} content={"Patient satisfaction starts with doctor satisfaction"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1d4rsxt --parallax" name={"kontakt"} style={{"paddingTop":306,"paddingBottom":302}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/130/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Modern and Innovative</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"fksddkd0uxa"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="js-anim  --anim2 --center pb--20 pt--20" name={"attributes"} style={{"backgroundColor":"#1f2b34","marginTop":0,"paddingLeft":0}} anim={"2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim3 --anim-s8 --shadow4" anim={"3"} animS={"8"} style={{"maxWidth":420}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Smart</span><br>"}>
              </Title>

              <Image className="--shadow4" style={{"maxWidth":419}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/10548043bb554b8b925fb464a66d8007_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/10548043bb554b8b925fb464a66d8007_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/10548043bb554b8b925fb464a66d8007_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38872/10548043bb554b8b925fb464a66d8007_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/38872/10548043bb554b8b925fb464a66d8007_s=860x_.jpg"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":""}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim3 --anim-s7 --shadow4" anim={"3"} animS={"7"} style={{"maxWidth":420,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Versatile</span><br>"}>
              </Title>

              <Image className="--shadow4" style={{"maxWidth":355}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/d6807b3a8864432dbcfe5919011e79cf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/d6807b3a8864432dbcfe5919011e79cf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/d6807b3a8864432dbcfe5919011e79cf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38872/d6807b3a8864432dbcfe5919011e79cf_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/38872/d6807b3a8864432dbcfe5919011e79cf_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s8 --shadow4" anim={"2"} animS={"8"} style={{"maxWidth":420}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Handy</span><br>"}>
              </Title>

              <Image className="--shadow4" style={{"maxWidth":317}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38872/e98467d25ceb4582aaa08549542970d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/e98467d25ceb4582aaa08549542970d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/e98467d25ceb4582aaa08549542970d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38872/e98467d25ceb4582aaa08549542970d8_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/38872/e98467d25ceb4582aaa08549542970d8_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l4"} style={{"paddingTop":173,"paddingBottom":170}} name={"tcaea96iu8c"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Image className="--shape5 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/399fc9d0261b4b129e4aa7ecfbed73eb_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/38872/399fc9d0261b4b129e4aa7ecfbed73eb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/399fc9d0261b4b129e4aa7ecfbed73eb_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image className="--shape5 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/7840edd0a8bc4d4bb5c39c604d55401b_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/38872/7840edd0a8bc4d4bb5c39c604d55401b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/7840edd0a8bc4d4bb5c39c604d55401b_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image className="--shape5 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38872/7ba5b67f5a5a4093a578ea12e28a6a3a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/38872/7ba5b67f5a5a4093a578ea12e28a6a3a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38872/7ba5b67f5a5a4093a578ea12e28a6a3a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38872/7ba5b67f5a5a4093a578ea12e28a6a3a_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--02" name={"bottom"} style={{"backgroundColor":"rgba(192, 192, 192, 1)","marginTop":38,"paddingTop":38,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"maxWidth":1291,"marginBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--center mb--02 mt--02" style={{"paddingTop":0,"paddingBottom":0,"paddingLeft":0,"paddingRight":0}}>
              
              <Title className="title-box title-box--style2 title-box--center fs--43 swpf--uppercase" content={"<a href=\"/contact\">Contact</a>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":334}} content={"www.smartmedicalpad.com<br>info@smartmedicalpad.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)","marginTop":0}} layout={"l1"} name={"kh8dvsp8m4"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--style5 title-box--center fs--26" style={{"paddingTop":8,"paddingBottom":6}} content={"Disclaimer"}>
              </Title>

              <Text className="text-box text-box--center fs--18 mt--0" content={"All patient names and data showed in all pictures&nbsp;on this website are completely imaginary and not real. It serves only to demonstrative purposes."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}